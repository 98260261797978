<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="admissionRoundId"
                  >
                    <v-select
                      v-model="filter.admissionRoundId"
                      :options="admissionRounds"
                      :reduce="option => option.value"
                      placeholder="Đợt tuyển sinh"
                      @input="readAdmissionForm"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="admissionFormId"
                  >
                    <v-select
                      v-model="filter.type"
                      :options="typeList"
                      :reduce="option => option.value"
                      placeholder="Loại giấy tờ"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="identification"
                    title="CCCD Thí sinh ứng tuyển"
                  >
                    <b-form-input
                      id="code"
                      v-model="filter.identification"
                      name="name"
                      type="number"
                      placeholder="CCCD Thí sinh ứng tuyển"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="ml-1"
                      @click="onSearchMainData"
                    >
                      <feather-icon icon="FilterIcon" /> Xem danh sách
                    </b-button>
                    <b-button
                      v-if="studentSuspendUpdatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="ml-1"
                      @click="onApproveSuspend(1)"
                    >
                      <feather-icon icon="CheckIcon" /> Xác nhận
                    </b-button>
                    <b-button
                      v-if="studentSuspendUpdatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="ml-1"
                      @click="onApproveSuspend(-1)"
                    >
                      <feather-icon icon="XOctagonIcon" /> Không xác nhận
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-0">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                </div>
                <vue-good-table
                  mode="remote"
                  style-class="vgt-table striped bordered"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'SV được chọn', clearSelectionText: 'Bỏ chọn' }"
                  @on-selected-rows-change="selectionChanged"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'status'"
                      :class="(props.row.status === 1) ? 'text-success font-weight-bold' : 'text-danger font-weight-bold'"
                    >
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'download'">
                      <b-img class="certificate-img" fluid :src="props.row.url" alt="Image 1"></b-img>
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          variant="info"
                          class="btn-icon"
                          size="sm"
                        >
                          <feather-icon icon="ListIcon" />
                        </b-button>
                        <b-button
                          :disabled="(props.row.status >= 1)"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BLink,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'ConfirmCertificate',
  directives: {
    Ripple,
  },
  components: {
    BImg, BLink,
    BFormInput,
    BButton,
    BButtonGroup,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        admissionRoundId: null,
        // admissionFormId: null,
        // admissionMajorId: null,
        type: 0,
        identification: null,
        code: null,
        sort: null,
        status: null,
      },
      currentMainData: undefined,
      extMainData: {
        planClassNum: 1,
        planClassData: [],
        planStudentClassData: [],
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      currentName: '',
      currentCode: '',
      selectedMainData: [],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'admissionConfirmCertificate/dataLists',
      totalRows: 'admissionConfirmCertificate/totalRows',
      statuses: 'admissionConfirmCertificate/statuses',
      resourceName: 'admissionConfirmCertificate/resourceName',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataListMajorByAdmissionForm: 'admissionMajor/dataListMajorByAdmissionForm',
      typeList: 'admissionConfirmCertificate/typeList'
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: 'CCCD thí sinh',
          field: 'identification',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'File ảnh',
          field: 'download',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Đợt tuyển sinh',
          field: 'admissionRoundName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: 'Xử lý',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    studentSuspendUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.STUDENT_SUSPEND)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'admissionConfirmCertificate/getData',
      updateData: 'admissionConfirmCertificate/updateData',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getMajorByAdmissionFormId: 'admissionMajor/getMajorByAdmissionFormId',
      getLinkAvatar: 'admissionCarousel/getLinkAvatar',
    }),
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({admissionRoundId: id})
      // await this.getMajorByAdmissionFormId()
    },
    // async selectAdmissionForm(id) {
    //   await this.getMajorByAdmissionFormId({ admissionFormId: id })
    // },
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = {}
    },
    onEditMainData(mainData) {
      this.isCopy = false
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName}? <span class="text-danger">${mainData.name}</span>`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainData(this.filter)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => ({
        applicantStudentId: e.applicantStudentId,
        admissionConfirmationId: e.admissionConfirmationId,
        admissionCertificateId: e.admissionCertificateId,
        admissionLanguageCertificateId: e.admissionLanguageCertificateId
      })) : []
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
    },
    async getMainDataFromStore() {
      this.isLoading = true
      if (this.filter.type === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Loại giấy tờ không được để trống!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
        return
      } else {
        try {
          if (this.filter.identification === '') {
            this.filter.identification = null;
          }
          await this.getMainData(this.filter);
          const fetchAvatarLink = async (item, codeKey) => {
            const { data } = await this.getLinkAvatar(item[codeKey]);
            return data;
          };

          for ( const item of this.mainData ) {
            const key = this.mainData.indexOf(item)
            let codeKey;
            switch (this.filter.type) {
              case 0:
                codeKey = 'admissionConfirmationCode';
                break;
              case 1:
                codeKey = 'admissionCertificateCode';
                break;
              case 2:
                codeKey = 'admissionLanguageCertificateCode';
                break;
              default:
                codeKey = null;
            }
            if (codeKey) {
              this.mainData[key].url = await fetchAvatarLink(item, codeKey);
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          });
        } finally {
          this.isLoading = false
        }
      }
      this.isLoading = false
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)
        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData() {
      await this.getMainDataFromStore()
    },
    async onApproveSuspend(status) {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Sinh viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      this.isLoading = true
      try {
        const request = {
          applicantStudentIds: this.selectedMainData,
          status,
        }
        const response = await this.updateData(request)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            await this.getMainDataFromStore()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      return null
    },
  },
}
</script>
<style type="text/css">
.certificate-img {
  display: block;
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 2px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.certificate-img:hover {
  transform: scale(5);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}
.vgt-responsive {
   overflow-x: visible !important;
}
</style>
